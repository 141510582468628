import React from "react"

import handbuch from "../images/books/handbuch.jpg"
import federwelt from "../images/presse/aktuelles_federwelt-cover.jpg"
import sandra from "../images/team/sandra.jpg"
import selfpublisher from "../images/presse/aktuelles_selfpublisher-cover.jpg"

const logo = "https://www.uschtrin.de/logo.png"

const JsonLd = () => (
  <>
    <script type="application/ld+json">
      {`{
      "@context": "https://schema.org",
      "@type": "Organization",
      "@id": "https://www.uschtrin.de#organisation",
      "url": "https://www.uschtrin.de",
      "sameAs": ["https://www.autorenwelt.de/organisation/uschtrin-verlag", "https://www.facebook.com/uschtrin.verlag"],
      "name": "Uschtrin Verlag",
      "telephone": "+49 81 43 3669 700",
      "description": "Der Uschtrin Verlag ist ein Fachverlag für Autorinnen und Autoren. Er verlegt die beiden Zeitschriften »Federwelt« und »der Selfpublisher« sowie das Standardwerk »Handbuch für Autorinnen und Autoren«.",
      "founder": {
        "@type": "person",
        "name": "Sandra Uschtrin",
        "sameAs": "https://www.autorenwelt.de/person/sandra-uschtrin"
      },
      "foundingDate": "1996",
      "foundingLocation": "München",
      "logo": "https://www.uschtrin.de/icon.png",
      "address": {
        "@type": "PostalAddress",
        "addressCountry": "Deutschland",
        "addressLocality": "Inning am Ammersee",
        "addressRegion": "BY",
        "postalCode": "82266",
        "streetAddress": "Leitenberg 8"
      },
      "image": [
        "${sandra}",
        "${handbuch}",
        "${logo}",
        "${federwelt}",
        "${selfpublisher}"
      ],
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+49 81 43 3669 700",
        "faxNumber": "+49 81 43 3669 155",
        "contactType": "Kundenservice",
        "email": "service@uschtrin.de",
        "hoursAvailable": {
          "opens": "09:00",
          "closes": "17:00",
          "dayOfWeek": [ "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"]
        },
        "availableLanguage": [
          {
                  "@type": "Language",
                  "name": "German"
          },
          {
                  "@type": "Language",
                  "name": "English"
          }
        ]
      }
      }`}
    </script>
    <script type="application/ld+json">
      {`{
      "@context": "https://schema.org",
      "@type": "WebSite",
      "@id": "https://uschtrin.de#website",
      "url": "https://uschtrin.de"
    }`}
    </script>
  </>
)

export default JsonLd
