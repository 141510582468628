import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import { RoughNotation } from "react-rough-notation"

import IndexJsonLD from "../components/indexJsonLD"
import Layout from "../components/layout"
import SEO from "../components/seo"

/** @jsx jsx */
import {
  jsx,
  Button,
  Heading,
  Text,
  Styled,
  Link as ThemeUILink,
} from "theme-ui"

const IndexPage = ({ data, location }) => {
  console.log(location)
  return (
    <Layout>
      <SEO title="Home" location={location} />
      <div
        sx={{
          display: "grid",
          gridGap: 4,
          alignItems: "center",
          gridTemplateColumns: ["auto", "1fr 350px"],
        }}
      >
        <main>
          <div
            style={{
              maxWidth: `512px`,
              minWidth: "200px",
              marginBottom: `1.45rem`,
            }}
          >
            <Img
              title="ChatGPT-Cover"
              alt="Cover Schreiben mit ChatGPT für Autorinnen und Autoren"
              fluid={data.chatgpt.childImageSharp.fluid}
            />
          </div>
        </main>
        <aside>
          <div
            sx={{
              maxWidth: 512,
              mx: "auto",
              px: 3,
              py: 4,
            }}
          >
            <Heading as="h2" sx={{ fontSize: 4, fontWeight: 900 }}>
              DER RATGEBER
            </Heading>
            <RoughNotation
              type="highlight"
              show={true}
              color="#fffd98"
              sx={{ color: "rgb(45, 55, 72)" }}
            >
              <p sx={{ fontSize: 4, fontWeight: "bold" }}>
                Die KI ChatGPT im Praxis-Test
              </p>
            </RoughNotation>
            <Link to="/buecher/uschtrin-schreiben-mit-chatgpt">
              <Button>Weitere Informationen</Button>
            </Link>
          </div>
        </aside>
      </div>
      <Heading as="h2" sx={{ fontSize: 4, pt: 4 }}>
        »Neuigkeiten«
      </Heading>
      <div
        sx={{
          display: "grid",
          gridGap: 3,
          gridTemplateColumns: `repeat(auto-fit, minmax(256px, 1fr))`,
          pt: 3,
        }}
      >
        <div sx={{ p: 2 }}>
          <Heading as="h3" sx={{ fontSize: 1 }}>
            Brandaktuell:
            <br />
            Die KI ChatGPT im Test!
          </Heading>

          <Text sx={{ fontSize: 1 }}>
            <RoughNotation
              type="highlight"
              show={true}
              color="#fffd98"
              multiline={true}
              sx={{ color: "rgb(45, 55, 72)" }}
            >
              Seit Mai 2023 im Buchhandel:
            </RoughNotation>{" "}
            »Schreiben mit ChatGPT für Autorinnen und Autoren. Von der
            Ideenfindung bis zur Vermarktung«.{` `}
            <Styled.a href="https://www.uschtrin.de/buecher/uschtrin-schreiben-mit-chatgpt">
              Als E-Book und Print.
            </Styled.a>
          </Text>
        </div>
        <div sx={{ p: 2 }}>
          <Heading as="h3" sx={{ fontSize: 1 }}>
            Das Handbuch als E-Book für nur 9,99 EUR
          </Heading>

          <Text sx={{ fontSize: 1 }}>
            Die 8. Ausgabe des »Handbuchs für Autorinnen und Autoren« von 2015
            gibt es seit Juli 2021 als E-Book für nur 9,99 EUR in diversen
            Online-Shops (ISBN 9783967460193). Die Printausgabe für 54,90 EUR
            ist bis auf wenige Exemplare vergriffen. Eine 9. Ausgabe ist derzeit
            nicht geplant.
          </Text>
        </div>
        <div sx={{ p: 2 }}>
          <Heading as="h3" sx={{ fontSize: 1 }}>
            Sandra Uschtrin BücherFrau des Jahres
          </Heading>

          <Text sx={{ fontSize: 1 }}>
            <RoughNotation
              type="highlight"
              show={true}
              color="#fffd98"
              multiline={true}
              sx={{ color: "rgb(45, 55, 72)" }}
            >
              Die BücherFrauen haben Sandra Uschtrin zur BücherFrau des Jahres
              2019 gewählt.
            </RoughNotation>{" "}
            »Wir hoffen«,{" "}
            <Styled.a href="https://www.buecherfrauen.de/inhalt/presse/presse-detail/buecherfrau-des-jahres-2019-sandra-uschtrin">
              heißt es in der Begründung
            </Styled.a>
            , dass die Wahl »einen wesentlichen Beitrag zu größerer Bekanntheit
            der Autorenwelt mit Autorenwelt-Shop und einen entscheidenden
            Anschub in der Aufbauphase dieser guten Sache leistet.«
          </Text>
        </div>
      </div>
      <IndexJsonLD />
    </Layout>
  )
}

export const query = graphql`
  query {
    chatgpt: file(relativePath: { eq: "books/cover_uschtrin_chatgpt.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 512) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

export default IndexPage
